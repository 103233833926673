import { authAxios } from "@/libs/axios";

import type {
  FetchAppointmentsPayload,
  FetchAppointmentsResponseData,
  AcceptAppointmentPayload,
  DeclineAppointmentPayload,
  RescheduleAppointmentPayload,
  CheckInAppointmentPayload,
  MarkAbsenceAppointmentPayload,
  FetchAppointmentPayload,
  FetchAppointmentResponseData,
} from "./appointment.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const appointmentApi = {
  fetchAppointments: (payload: FetchAppointmentsPayload) => {
    return authAxios.get<AxiosResponseData<FetchAppointmentsResponseData>>(
      "appointments",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchAppointment: (payload: FetchAppointmentPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.get<AxiosResponseData<FetchAppointmentResponseData>>(
      `appointments/${id}`,
      {
        params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  acceptAppointment: (payload: AcceptAppointmentPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(
      `appointments/accept/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  declineAppointment: (payload: DeclineAppointmentPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(
      `appointments/decline/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  rescheduleAppointment: (payload: RescheduleAppointmentPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(
      `appointments/reschedule/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  checkInAppointment: (payload: CheckInAppointmentPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(
      `appointments/check-in/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  markAbsenceAppointment: (payload: MarkAbsenceAppointmentPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(
      `appointments/absence/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default appointmentApi;
